
import './form-date.scss';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import DatePicker from '@/components/form/DatePickerIcon.vue';
import FormTimePickerActivatorsRange from '@/components/form/TimePicker/activators/Range.vue';

export default {
  name: 'ReviseFormDate',
  components: {
    FormTimePickerActivatorsRange,
    DatePicker,
    AppCol,
    AppRow,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
    start: {
      type: String,
      required: true,
    },
    end: {
      type: String,
      required: true,
    },
  },
  computed: {
    interval(): string {
      const { start, end } = this as any;

      return `${start}-${end}`;
    },
  },
};
