import { watch } from 'vue';
import moment from 'moment';
import UserModule from '@/store/user';
import FilterFactory from '@/lib/layouts/page/filterFactory';
import { SelectItem } from '@/lib/formFactory/select.interface';

export default class ShopsFilter {
  filterHandlers = {
    update: 'reviseFilter/updateFilter',
    reset: 'reviseFilter/resetFilter',
    resetState: 'reviseFilter/resetFilterState',
    removeSelected: 'reviseFilter/removeSelectedFilter',
  };
  filterModel: {};

  get defaultMonthPeriod(): SelectItem {
    const currentMonth = moment();
    const id = currentMonth.format('YYYY-MM');
    const value = currentMonth.format('MMMM YYYY').replace(/^[а-я]/, (firstLetter) => firstLetter.toUpperCase());

    return { id, value };
  }

  constructor() {
    const filter = new FilterFactory();

    filter.createSelectEntity('monthPeriod');
    filter.setTitle('monthPeriod', 'По месяцу');
    filter.setName('monthPeriod', 'select_monthPeriod');
    filter.setAction('monthPeriod', 'reviseReportFilter/updateSelect');
    filter.setInitAction('monthPeriod', 'reviseReport/initMonthsSelect');
    filter.setCurrent('monthPeriod', this.defaultMonthPeriod);

    filter.createMultiSelectEntity('serviceTypes');
    filter.setTitle('serviceTypes', 'Тип услуги');
    filter.setName('serviceTypes', 'multi_select_service_type');
    filter.setClassName('serviceTypes', 'cp-width--200');
    filter.setAction('serviceTypes', 'reviseReportFilter/updateMultiSelect');
    filter.setInitAction('serviceTypes', 'reviseReport/initServiceTypeSelect');
    filter.setSearchEnabled('serviceTypes', true);

    filter.createSelectEntity('partners');
    filter.setTitle('partners', 'Партнёр');
    filter.setName('partners', 'select_partner');
    filter.setClassName('partners', 'cp-width--200');
    filter.setAction('partners', 'reviseReportFilter/updateSelect');
    filter.setInitAction('partners', 'reviseReport/initPartnersSelect');
    filter.setClearAction('partners', 'reviseReportFilter/resetSelect');
    filter.setSearchEnabled('partners', true);

    filter.createSearchEntity('fio');
    filter.setPlaceholder('fio', 'По ФИО');
    filter.setName('fio', 'search_fullName');
    filter.setAction('fio', 'reviseReportFilter/updateSearch');

    filter.createCheckboxEntity('showDone');
    filter.setName('showDone', 'checkbox_showDone');
    filter.setAction('showDone', 'reviseReportFilter/updateCheckbox');
    filter.setListElement('showDone', {
      id: 'showDone',
      name: 'Все заказы',
      checked: true,
    });

    watch(
      () => UserModule.isSupervisor,
      (isSupervisor = false) => {
        filter.setIsHidden('partners', !isSupervisor);
      },
      { immediate: true }
    );

    this.filterModel = filter.filterModel;
  }
}
