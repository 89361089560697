
import { Component, Vue, Watch } from '@/lib/decorator';
import { changeQueryParamsSilently } from '@/lib/Utils';
import { debounceDecorator, skipFirstCall } from '@/lib/util/helpers';
import TitleReturn from '@/components/TitleReturn.vue';
import FormInput from '@/components/form/Input/index.vue';
import FormSelect from '@/components/form/Select/index.vue';
import AppTable from '@/components/ui/table/Table.vue';
import StatusRevise from './_statusRevise.vue';
import ReviseReportModule from '@/store/tasks/revise/report';
import ArrowReturn from '@/components/icons/ArrowReturn.vue';
import IconFont from '@/components/icons/IconFont.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import ModalBlock from '@/components/Modal.vue';
import ModalForm from './_form.vue';
import FormSimpleSwitch from '@/components/form/Switch/SimpleSwitch.vue';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { FilterModel } from '@/lib/layouts/page/filter.interface';
import FormMultiSelect from '@/components/form/MultiSelect/index.vue';
import { Checkbox } from '@/interfaces/filter.interface';
import { GuiFormButton, GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    GuiFormButton,
    FormSimpleSwitch,
    ModalForm,
    ModalBlock,
    AppRow,
    AppCol,
    IconFont,
    ArrowReturn,
    StatusRevise,
    AppTable,
    FormSelect,
    FormInput,
    TitleReturn,
    FormMultiSelect,
  },
  beforeMount() {
    this.shopId = this.$route.params.shopId as string;
  },
})
export default class ReviseMain extends Vue {
  shopId = '';
  modalData: any = {};
  modalEdit = false;
  isDownloadInProgress = false;
  isLoading = false;

  get isTest(): boolean {
    return ReviseReportModule.isTest;
  }

  get titleEdit(): string {
    return ReviseReportModule.title;
  }

  get hasCabinet(): boolean {
    return ReviseReportModule.hasCabinet;
  }

  get modal(): boolean {
    return ReviseReportModule.modal;
  }

  get settings(): PageInterface {
    return ReviseReportModule.pageSettings;
  }

  get filterModel(): FilterModel {
    return ReviseReportModule.filter.filterSettings.filterModel;
  }

  get fio() {
    return this.filterModel.fio;
  }

  get monthPeriod() {
    return this.filterModel.monthPeriod;
  }

  get serviceTypes() {
    return this.filterModel.serviceTypes;
  }

  get checkedServiceTypes() {
    const serviceTypeItems = (this.serviceTypes?.list ?? []) as Checkbox[];

    return serviceTypeItems.filter(({ checked }) => checked);
  }

  get partners() {
    return this.filterModel.partners;
  }

  get showDone() {
    return this.filterModel.showDone;
  }

  get modalTitle() {
    if (!this.modalData && !this.modalData.status) {
      return '';
    }

    switch (this.modalData.status) {
      case 'not_sent':
        return 'Нет данных по отработке';
      case 'discussion':
      case 'reconciliation':
        return 'Расхождение по отработке';
      case 'done':
        return this.modalEdit ? 'Расхождение по отработке' : 'Данные согласованы';
      default:
        return '';
    }
  }

  @skipFirstCall
  @Watch('filterModel.monthPeriod.current')
  changeQueryStringMonthPeriod(option: { id: string }): void {
    changeQueryParamsSilently({ ...this.$route.query, monthPeriod: option.id });
  }

  returnBefore() {
    this.$router.push({ name: 'revise' });
  }

  @debounceDecorator(600)
  async updateSearch(value: string) {
    ReviseReportModule.filter.updateSearch({ key: 'fio', value });

    await ReviseReportModule.filter.updateFilter();
    await ReviseReportModule.getList();
  }

  async handleSelect(selected: {}, params: { key: string; action: string }) {
    await this.$store.dispatch(params.action, {
      key: params.key,
      value: selected,
    });

    await this.updateFilter();
  }

  async handleCheckbox(value: any, key: string, actionName: string) {
    await this.$store.dispatch(actionName, {
      key,
      checkbox: {
        id: key,
        bool: value.target.checked,
      },
    });

    await this.updateFilter();
  }

  async updateFilter() {
    this.isLoading = true;

    try {
      await ReviseReportModule.filter.updateFilter();
      await ReviseReportModule.getList();
    } finally {
      this.isLoading = false;
    }
  }

  openModal(data: any) {
    this.modalData = data;
    ReviseReportModule.context.commit('SET_MODAL', true);
  }

  closeModal() {
    ReviseReportModule.context.commit('SET_MODAL', false);
  }

  async downloadReport(): Promise<void> {
    this.isDownloadInProgress = true;

    try {
      await ReviseReportModule.downloadReviseReport();
    } finally {
      this.isDownloadInProgress = false;
    }
  }

  async mounted() {
    let { monthPeriod, partnerUuid } = this.$route.query;
    monthPeriod = monthPeriod?.toString() ?? '';
    partnerUuid = partnerUuid?.toString() ?? '';

    await ReviseReportModule.setShopId(this.shopId);
    await ReviseReportModule.init({ monthPeriod, partnerUuid });
  }
}
