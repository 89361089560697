
import './icon-item.scss';
import IconFont from '@/components/icons/IconFont.vue';

export default {
  name: 'IconItem',
  props: {
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    icon: {
      type: String,
    },
    iconSize: {
      type: String,
      default: '14',
    },
  },
  components: {
    IconFont,
  },
};
