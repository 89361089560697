import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "shift-info-date-range__item" }
const _hoisted_2 = { class: "shift-info-date-range__item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_picker = _resolveComponent("date-picker")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_form_time_picker_activators_range = _resolveComponent("form-time-picker-activators-range")!
  const _component_app_row = _resolveComponent("app-row")!

  return (_openBlock(), _createBlock(_component_app_row, {
    class: "shift-info-date",
    wrap: "nowrap",
    "align-items": "center"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_col, { class: "shift-info-date__calendar" }, {
        default: _withCtx(() => [
          ($props.date)
            ? (_openBlock(), _createBlock(_component_date_picker, {
                key: `datepicker_${$props.date}`,
                data: { value: $props.date },
                disabled: ""
              }, null, 8, ["data"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_app_col, { class: "shift-info-date__range shift-info-date-range" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_form_time_picker_activators_range, { value: $props.start }, null, 8, ["value"])
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_form_time_picker_activators_range, { value: $props.end }, null, 8, ["value"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}