
import './form.scss';
import { defineComponent } from 'vue';
import moment from 'moment';
import ModalBtnBottom from '@/layouts/ModalBtnBottom.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import IconFont from '@/components/icons/IconFont.vue';
import FormButton from '@/components/form/Button/index.vue';
import { GuiLoader } from '@library/gigant_ui';
import ReviseFormDate from './_formDate.vue';
import ReviseFormInfo from './_formInfo.vue';
import ReviseReportModule from '@/store/tasks/revise/report';
import { dateFormat, minutesToTime, timeToMinutes } from '@/lib/Utils';
import { ShiftEmployeeStatus } from '@/lib/util/consts';
import { ReviseModelInterface } from '@/lib/Revise';
import { getReviseDuration } from '@/api/revise';
import { getLunchSchemes } from '@/api/client';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { strings } from '@/lib/stringConst';
import { ShiftEmployeeInterface, ShiftEntityInterface } from '@/store/shops/shiftModalParams';

export default defineComponent({
  name: 'ModalForm',
  components: {
    ModalBtnBottom,
    ReviseFormInfo,
    ReviseFormDate,
    FormButton,
    IconFont,
    AppCol,
    AppRow,
    GuiLoader,
  },
  props: {
    shiftEmployeeId: {
      type: String,
      required: true,
    },
    shopName: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    shopId: {
      type: String,
      required: true,
    },
    hasClientCabinet: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const gigantInfo: {
      status?: string;
      substatus?: string;
      lunch?: string;
      end?: string;
      start?: string;
      work?: string;
      quantity?: number;
      price?: number;
      comment?: string;
    } = {};
    const lkInfo: {
      status?: string;
      substatus?: string;
      lunch?: string;
      end?: string;
      start?: string;
      quantity?: number;
      work?: string;
      price?: number;
    } = {};

    return {
      isLoading: true,
      isEdit: false,
      lkInfo,
      gigantInfo,
      shiftLunch: 0,
      shiftLunchIsPaid: false,
      time: 0,
      lkTime: 0,
      shiftId: 0,
      shiftLunchSchemes: [{ duration: 0, isPaid: false }],
    };
  },
  computed: {
    shiftEmployee(): ShiftEmployeeInterface | undefined {
      return (this.shiftModel as ShiftEntityInterface)?.shiftEmployees.find(({ id }) => id === this.shiftEmployeeId);
    },
    lkWarning(): string | boolean {
      return +this.warningMinutes < 0 && this.warning;
    },
    gigantWarning(): string | boolean {
      return +this.warningMinutes > 0 && this.gigantInfo.status !== 'not_sent' && this.warning;
    },
    gigantInfoEditable(): boolean {
      const { status, isEdit } = this;

      return (
        ((status === 'done' || status === 'not_sent') && isEdit) || status === 'reconciliation' || status === 'discussion'
      );
    },
    model() {
      return ReviseReportModule.model as ReviseModelInterface;
    },
    shiftModel(): ShiftEntityInterface {
      return ReviseReportModule.shiftModel as ShiftEntityInterface;
    },
    isIncompleteShift(): boolean {
      const { date, end } = this.model;

      if (!date || !end) {
        return false;
      }

      const shiftEnd = moment(`${date} ${end}`, 'DD.MM.YYYY HH:mm')
        .utcOffset(ReviseReportModule.timeZone, true)
        .toDate()
        .valueOf();

      return Date.now() < shiftEnd;
    },
    warningMinutes(): number {
      const { gigantInfo, lkInfo } = this;

      return timeToMinutes(gigantInfo.work || '00:00') - timeToMinutes(lkInfo.work || '00:00');
    },
    warning(): string {
      return minutesToTime(Math.abs(this.warningMinutes));
    },
    isDisabledSubmitButton(): boolean {
      return (
        this.isIncompleteShift &&
        [ShiftEmployeeStatus.IsPlanned, ShiftEmployeeStatus.CameOut].includes(this.gigantInfo.status as ShiftEmployeeStatus)
      );
    },
    submitButtonTitle(): string {
      return this.hasClientCabinet ? 'Отправить корректировку' : 'Сверить';
    },
    isHourly(): boolean {
      return !!this.model.unitType?.isHourly;
    },
    hasCabinet(): boolean {
      return ReviseReportModule.hasCabinet;
    },
  },
  watch: {
    shiftEmployeeId: {
      immediate: true,
      async handler(id: string) {
        this.cancelEdit(false);
        await ReviseReportModule.getReportInfo(id);
        this.isLoading = false;
      },
    },
    async model(model: any) {
      this.lkInfo = model.lk;
      this.lkInfo.work =
        model.lk.status === 'did_not_go_out' || model.lk.status === 'fake_call' ? '00:00' : minutesToTime(model.lkTime);
      this.lkInfo.price = model.lkPrice;
      this.lkInfo.quantity = model.lkQuantity;
      this.gigantInfo = { ...model.gigant };
      this.gigantInfo.work = minutesToTime(model.time);
      this.gigantInfo.quantity = model.quantity;
      this.gigantInfo.price = model.price;
      this.shiftLunch = model.shiftLunch;
      this.shiftLunchIsPaid = model.shiftLunchIsPaid;
      this.time = model.time;
      this.lkTime = model.lkTime;
      this.shiftId = model.shiftId;
      this.shiftLunchSchemes = await getLunchSchemes(this.shiftId.toString());
    },
  },
  methods: {
    timeFormat(dateString: string) {
      const offset = (this.shiftModel as ShiftEntityInterface).clientMarket.timeZone;

      return moment(dateString, 'YYYY-MM-DD HH:mm').add(offset, 'hour').format('DD.MM.YYYY HH:mm');
    },
    async change(data: { key: 'start' | 'end' | 'lunch' | 'status' | 'quantity' | 'price'; value: any }) {
      this.gigantInfo[data.key] = data.value;

      if (data.key === 'status' && data.value !== 'fake_call') {
        this.gigantInfo['substatus'] = undefined;
      }

      if (!['quantity', 'price', 'comment'].includes(data.key)) {
        const calculatedWork = await this.getWork(this.gigantInfo);
        this.gigantInfo.work = calculatedWork.duration;
        this.time = calculatedWork.time;

        if (this.isHourly) {
          this.gigantInfo.price = calculatedWork.price;
        }
      }
    },
    async getWork(data: any) {
      const { end, start, lunch, status, substatus } = data;

      let time = 0;
      let price = 0;

      if (status === 'did_not_go_out' || status === 'fake_call' || substatus === 'fake_call') {
        return { duration: '00:00', time, price };
      }

      if (start && end && lunch) {
        const infoTimeWork = {
          start: start ? start + ':00' : '',
          end: end ? end + ':00' : '',
          lunch: timeToMinutes(lunch).toString(),
        };

        if (this.shiftEmployeeId) {
          try {
            const response = await getReviseDuration(this.shiftEmployeeId, infoTimeWork);

            [time, price] = [response.duration, response.price];
          } catch (error) {
            ResponseHandlerModule.handleResponseError({
              error,
              checkErrorFields: true,
              defaultMessage: strings.UNKNOWN_ERROR,
            });
          }
        }
      }

      return {
        time,
        price,
        duration: minutesToTime(time),
      };
    },
    toEdit() {
      this.isEdit = true;
      this.$emit('edit', true);
    },
    async cancelEdit(flag: boolean) {
      this.isEdit = false;

      if (flag) {
        this.gigantInfo = { ...(this.model as ReviseModelInterface).gigant };
        this.gigantInfo.quantity = this.model.quantity;
        this.gigantInfo.price = this.model.price;
        this.gigantInfo.comment = this.model.comment;

        const calculatedWork = await this.getWork(this.gigantInfo);
        this.gigantInfo.work = calculatedWork.duration;
        this.time = calculatedWork.time;

        if (this.isHourly) {
          this.gigantInfo.price = calculatedWork.price;
        }
      }

      this.$emit('edit', false);
    },
    save(isAgree: boolean) {
      let data = this.gigantInfo;

      if (isAgree) {
        data = this.lkInfo;
        if (data.substatus === 'fake_call') {
          data.status = data.substatus;
        }
      }

      const result: {
        status?: string;
        lunch?: number;
        end?: string;
        start?: string;
        comment?: string;
        quantity?: number;
        price?: number;
      } = {
        status: data.status,
      };

      if (data.status !== 'did_not_go_out' && data.status !== 'fake_call') {
        const defaultTime = '00:00';
        result.start = dateFormat(data.start || defaultTime, { from: 'HH:mm', to: 'HH:mm:ss' });
        result.end = dateFormat(data.end || defaultTime, { from: 'HH:mm', to: 'HH:mm:ss' });
        result.lunch = timeToMinutes(data.lunch || defaultTime);
        result.quantity = data.quantity;
        result.price = data.price;

        if (this.isHourly) {
          result.quantity = timeToMinutes(data.work || defaultTime);
        }
      }

      if (this.gigantInfo.comment) {
        result.comment = this.gigantInfo.comment;
      }

      ReviseReportModule.setReport({ id: this.shiftEmployeeId, data: result });
    },
  },
});
