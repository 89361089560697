import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "cp-main-inner__wrapper" }
const _hoisted_2 = { class: "cp-flex cp-flex--justify-between cp-flex--align-center cp-height__48" }
const _hoisted_3 = { class: "cp-uppercase cp-title-page cp-width--230" }
const _hoisted_4 = { class: "cp-badges-container" }
const _hoisted_5 = {
  key: 0,
  class: "cp-badge cp-badge_test"
}
const _hoisted_6 = { class: "cp-flex cp-flex--align-center cp-flex--justify-between" }
const _hoisted_7 = {
  ref: "form",
  class: "cp-flex align-items-center cp-filter_line__wrapper"
}
const _hoisted_8 = {
  key: 0,
  class: "cp-height--full cp-flex cp-flex--column cp-flex--justify-between"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_title_return = _resolveComponent("title-return")!
  const _component_form_select = _resolveComponent("form-select")!
  const _component_form_multi_select = _resolveComponent("form-multi-select")!
  const _component_icon_font = _resolveComponent("icon-font")!
  const _component_form_input = _resolveComponent("form-input")!
  const _component_form_simple_switch = _resolveComponent("form-simple-switch")!
  const _component_gui_form_button = _resolveComponent("gui-form-button")!
  const _component_gui_loader = _resolveComponent("gui-loader")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_app_row = _resolveComponent("app-row")!
  const _component_status_revise = _resolveComponent("status-revise")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_modal_form = _resolveComponent("modal-form")!
  const _component_modal_block = _resolveComponent("modal-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.settings.title), 1),
      _createVNode(_component_title_return, {
        class: "cp-margin__t--small cp-margin__b--small cp-width--full mr-title__wrapper__center-title",
        onClick: _ctx.returnBefore
      }, {
        "icon-badge": _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.isTest)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Test"))
              : _createCommentVNode("", true)
          ])
        ]),
        default: _withCtx(() => [
          _createTextVNode(" " + _toDisplayString(_ctx.titleEdit), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("form", _hoisted_7, [
        (_ctx.monthPeriod)
          ? (_openBlock(), _createBlock(_component_form_select, {
              key: 0,
              class: "cp-width--152 cp-margin__ri--sx",
              modelValue: _ctx.monthPeriod.current?.value ? _ctx.monthPeriod.current : _ctx.monthPeriod.list?.[0],
              items: _ctx.monthPeriod.list,
              onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSelect($event, _ctx.monthPeriod)))
            }, null, 8, ["modelValue", "items"]))
          : _createCommentVNode("", true),
        (_ctx.serviceTypes && _ctx.serviceTypes.list?.length)
          ? (_openBlock(), _createBlock(_component_form_multi_select, {
              key: 1,
              class: "cp-width--246 cp-margin__ri--sx",
              label: "Тип услуги",
              modelValue: _ctx.checkedServiceTypes,
              items: _ctx.serviceTypes.list,
              disabled: _ctx.isLoading,
              "is-search-enabled": "",
              onUpdateItem: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSelect($event, _ctx.serviceTypes)))
            }, null, 8, ["modelValue", "items", "disabled"]))
          : _createCommentVNode("", true),
        (_ctx.partners && !_ctx.partners.isHidden)
          ? (_openBlock(), _createBlock(_component_form_select, {
              key: 2,
              class: "cp-width--246 cp-margin__ri--sx",
              label: "Партнёр",
              modelValue: _ctx.partners.current,
              items: _ctx.partners.list,
              disabled: ""
            }, null, 8, ["modelValue", "items"]))
          : _createCommentVNode("", true),
        _createVNode(_component_icon_font, {
          class: "cp-padding__t--sm cp-margin__r--5 cp-icon-color-primary",
          icon: "user-search",
          size: "18"
        }),
        _createVNode(_component_form_input, {
          class: "cp-width--358 cp-margin__r--small",
          modelValue: _ctx.fio.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.fio.value) = $event)),
          placeholder: "Начните вводить имя исполнителя",
          clear: "",
          onUpdate: _ctx.updateSearch
        }, null, 8, ["modelValue", "onUpdate"]),
        _createVNode(_component_form_simple_switch, {
          modelValue: _ctx.showDone.checked,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showDone.checked) = $event)),
          label: "Все заказы",
          class: "cp-margin__b-no cp-margin__l--s cp-desc cp-margin__t--medium",
          onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleCheckbox($event, _ctx.showDone.key, _ctx.showDone.action)))
        }, null, 8, ["modelValue"])
      ], 512),
      _createVNode(_component_gui_form_button, {
        text: 'Выгрузить',
        class: "cp-fs-14 cp-margin__l--sx-2",
        disabled: _ctx.isDownloadInProgress,
        onClick: _ctx.downloadReport,
        icon: 'icon-download'
      }, null, 8, ["disabled", "onClick"])
    ]),
    (Object.keys(_ctx.settings.table).length === 0)
      ? (_openBlock(), _createBlock(_component_gui_loader, {
          key: 0,
          "absolute-center": ""
        }))
      : _createCommentVNode("", true),
    (Object.keys(_ctx.settings.table).length)
      ? (_openBlock(), _createBlock(_component_app_table, {
          key: 1,
          class: "cp-margin__t--small",
          style: {"height":"calc(100vh - 120px)"},
          headers: _ctx.settings.table.titles,
          items: _ctx.settings.table.rows,
          "hide-selectable-count-elements-on-page": ""
        }, {
          "item-fullName": _withCtx(({ item }) => [
            _createVNode(_component_app_row, { direction: "column" }, {
              default: _withCtx(() => [
                _createVNode(_component_app_col, { class: "font-14" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.fullName), 1)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_app_col, { class: "font-second-small" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.serviceType), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024)
          ]),
          item: _withCtx(({ value }) => [
            value
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value, (item) => {
                    return (_openBlock(), _createBlock(_component_status_revise, {
                      key: item.quantity,
                      quantity: item.quantity,
                      status: item.status,
                      isHourly: item.unitType.isHourly,
                      unitType: item.unitType.shortName,
                      onClick: ($event: any) => (_ctx.openModal(item))
                    }, null, 8, ["quantity", "status", "isHourly", "unitType", "onClick"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["headers", "items"]))
      : _createCommentVNode("", true),
    (_ctx.modal)
      ? (_openBlock(), _createBlock(_component_modal_block, {
          key: 2,
          class: "cp-shift-edit__modal",
          title: _ctx.modalTitle,
          onClick: _ctx.closeModal
        }, {
          default: _withCtx(() => [
            _createVNode(_component_modal_form, {
              "shift-employee-id": _ctx.modalData.clientMarketShiftEmployeeId,
              "shop-name": _ctx.titleEdit,
              "has-client-cabinet": _ctx.hasCabinet,
              status: _ctx.modalData.status,
              "shop-id": _ctx.shopId,
              onEdit: _cache[5] || (_cache[5] = ($event: any) => (_ctx.modalEdit = $event))
            }, null, 8, ["shift-employee-id", "shop-name", "has-client-cabinet", "status", "shop-id"])
          ]),
          _: 1
        }, 8, ["title", "onClick"]))
      : _createCommentVNode("", true)
  ]))
}