<template>
  <div class="revise-info-item">
    <app-row class="revise-info-item__title">
      <app-col>
        {{ title }}
      </app-col>
    </app-row>

    <app-row class="revise-info-item__form" align-items="center" justify-content="space-between">
      <app-col :grow="0" style="flex-basis: 105px">
        <form-select :disabled="!editable" :model-value="status" :items="statusItems" @update="changeStatus($event.id)" />
      </app-col>

      <app-col :grow="0">
        <form-time-picker
          :disabled="disabled"
          :model-value="info.start"
          @update="$emit('change', { key: 'start', value: $event.value })"
        >
          <icon-item :value="isPositiveStatus && info.start ? info.start : '-'" :disabled="disabled" icon="in" />
        </form-time-picker>
      </app-col>

      <app-col :grow="0">
        <form-time-picker
          :disabled="disabled"
          :model-value="info.end"
          @update="$emit('change', { key: 'end', value: $event.value })"
        >
          <icon-item :value="isPositiveStatus && info.end ? info.end : '-'" :disabled="disabled" icon="out" />
        </form-time-picker>
      </app-col>

      <app-col :grow="0" class="cp-employee-shift-time__range">
        <template v-if="isPositiveStatus">
          <icon-font size="14" icon="lunch" :class="!disabled ? 'cp-icon-secondary' : ''" />&nbsp;&nbsp;
          <form-select
            :disabled="disabled"
            style="width: 115px; font-size: 14px"
            :tooltip="lunchTooltip"
            v-model="lunchModel"
            :items="lunchSchemesItems"
            v-tooltip="'Продолжительность обеда'"
            :clear="false"
            @update="updateLunch"
            isPaid
          />
        </template>
        <icon-item v-else value="-" disabled icon="lunch" />
      </app-col>

      <app-col v-if="keepWarningColumn" :grow="0">
        <icon-item v-if="warning" :value="warning" class="warning" icon="warning" disabled />
        <div v-else style="width: 80px" />
      </app-col>

      <app-col :grow="0" class="shift-info-item shift-info-item--employee-quantity">
        <span class="shift-info-item__text quantity-field">
          <span :class="!disabled && !isHourly ? 'cp-icon-secondary' : ''">Выполнено:&nbsp;</span>

          <template v-if="!isPositiveStatus">-</template>
          <template v-else-if="disabled || isHourly">
            {{ isHourly ? timeWork : quantity }}
            {{ unitType?.shortName || '' }}
          </template>
          <form-input
            v-else
            class="cp-width--80"
            :model-value="initialQuantity"
            :maskConfig="{ alias: 'numeric', allowMinus: false, digits: 2, max: 9999999 }"
            @update="updateQuantity"
          />
        </span>
      </app-col>
      <app-col :grow="0" class="shift-info-item shift-info-item--employee-price">
        <span :class="!(disabled || isHourly || isDoneShift) ? 'cp-icon-secondary' : ''">К выплате:&nbsp;</span>
        <template v-if="!isPositiveStatus || (disabled && price === 0)">-</template>
        <template v-else-if="disabled || isHourly || (isDoneShift && !isDirtyQuantity)">{{ price }} ₽</template>
        <form-input
          v-else
          class="cp-width--80"
          :model-value="initialPrice"
          :maskConfig="{ alias: 'numeric', allowMinus: false, digits: 2, max: 9999999 }"
          @update="updatePrice"
        />
      </app-col>

      <form-textarea
        v-if="isReconciliation"
        :model-value="comment"
        :disabled="disabled"
        placeholder="Комментарий"
        class="cp-employee-shift__comment textarea_fix_width"
        :rows="1"
        @update="updateComment"
      />
    </app-row>
  </div>
</template>

<script>
import { minutesToTime, timeToMinutes } from '@/lib/Utils';
import { ShiftEmployeeStatus } from '@/lib/util/consts';
import FormTimePicker from '@/components/form/TimePicker/index.vue';
import FormTextarea from '@/components/form/Textarea/index.vue';
import FormInput from '@/components/form/Input/index.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import FormSelect from '@/components/form/Select/index.vue';
import IconFont from '@/components/icons/IconFont.vue';
import IconItem from './_iconItem.vue';
import './form-info.scss';

export default {
  name: 'ReviseFormInfo',
  emits: ['change'],
  components: {
    FormSelect,
    IconItem,
    FormTimePicker,
    FormTextarea,
    FormInput,
    AppCol,
    AppRow,
    IconFont,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    info: {
      type: Object,
      required: true,
    },
    warning: {
      type: String,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    incompleteShift: {
      type: Boolean,
      default: false,
    },
    shiftEmployeeId: {
      type: String,
      default: '0',
    },
    shiftLunch: {
      type: Number,
      default: 0,
    },
    shiftLunchIsPaid: {
      type: Boolean,
      default: false,
    },
    shiftModel: {
      type: Object,
    },
    time: {
      type: Number,
      default: 0,
    },
    comment: {
      type: String,
      default: '',
    },
    shiftStatus: {
      type: String,
      default: '',
    },
    unitType: {
      type: Object,
    },
    shiftId: {
      type: Number,
      required: true,
    },
    shiftLunchSchemes: {
      type: Array,
    },
    keepWarningColumn: {
      type: Boolean,
    },
  },
  data() {
    return {
      statusItems: [
        { id: ShiftEmployeeStatus.CameOut, value: 'Явка' },
        { id: ShiftEmployeeStatus.DidNotGoOut, value: 'Неявка' },
        { id: ShiftEmployeeStatus.FakeCall, value: 'Ложный вызов' },
      ],
      clientMarketShiftId: 0,
      initialQuantity: 0,
      initialPrice: 0,
    };
  },
  computed: {
    shiftEmployee() {
      return this.shiftModel?.shiftEmployees.find(({ id }) => id === this.shiftEmployeeId);
    },
    quantity() {
      return (this.isPositiveStatus && this.info.quantity) || 0;
    },
    price() {
      return (this.isPositiveStatus && this.info.price) || 0;
    },
    timeWork() {
      return +(this.time / 60).toFixed(2);
    },
    lunchMinutes() {
      return minutesToTime(this.shiftLunch);
    },
    lunchIsPaid() {
      return this.shiftLunchIsPaid;
    },
    lunchSchemesFull() {
      return this.shiftLunchSchemes;
    },
    lunchTooltip() {
      return this.lunchModel.isPaid ? 'Оплачивается' : 'Не оплачивается';
    },
    lunchSchemesItems() {
      let result = [];

      for (const { duration, isPaid } of this.lunchSchemesFull) {
        const schemeDuration = minutesToTime(duration);
        result.push({ value: schemeDuration, isPaid: Boolean(isPaid), disabled: true });
      }

      result.sort((a, b) => timeToMinutes(a.value) - timeToMinutes(b.value));

      return result;
    },
    lunchModel() {
      if (this.info.lunch) {
        const [hour, minute] = this.info.lunch.split(':').map(Number);
        const minutes = hour * 60 + minute;
        const currentScheme = this.lunchSchemesFull.find((item) => item.duration == minutes);

        if (this.info.lunch === this.lunchMinutes) {
          return { value: this.lunchMinutes, isPaid: this.lunchIsPaid };
        }

        if (currentScheme) {
          return { value: this.info.lunch, isPaid: Boolean(currentScheme.isPaid) };
        }

        return { value: this.info.lunch, isPaid: false };
      }

      return { value: '00:00', isPaid: false };
    },
    status() {
      const { statusItems, info, incompleteShift } = this;
      let currentStatus = info.status;

      if (info.substatus === ShiftEmployeeStatus.FakeCall) {
        currentStatus = ShiftEmployeeStatus.FakeCall;
        this.changeStatus(ShiftEmployeeStatus.FakeCall);
      } else if (incompleteShift && info.status === ShiftEmployeeStatus.IsPlanned) {
        currentStatus = ShiftEmployeeStatus.CameOut;
      }

      return statusItems.find((option) => option.id === currentStatus) || statusItems[0];
    },
    isPositiveStatus() {
      return this.status.id === 'came_out';
    },
    disabled() {
      return !this.isPositiveStatus || !this.editable;
    },
    isReconciliation() {
      if (
        this.shiftStatus === 'reconciliation' ||
        this.shiftStatus === 'discussion' ||
        (this.shiftStatus === 'done' && this.editable)
      ) {
        return true;
      }

      return false;
    },
    isHourly() {
      return !!this.unitType?.isHourly;
    },
    isDoneShift() {
      return this.shiftStatus === 'done';
    },
    isDirtyQuantity() {
      return +this.initialQuantity !== +this.quantity;
    },
  },
  methods: {
    changeStatus(value) {
      this.$emit('change', { key: 'status', value });
    },
    updateLunch(lunch) {
      this.$emit('change', { key: 'lunch', value: lunch.value });
    },
    updateQuantity(value) {
      this.$emit('change', { key: 'quantity', value: +value });
    },
    updatePrice(value) {
      this.$emit('change', { key: 'price', value: +value });
    },
    updateComment(value) {
      this.$emit('update:comment', value);
    },
  },
  watch: {
    disabled: {
      immediate: true,
      handler(isDisabled) {
        if (!isDisabled) {
          this.initialQuantity = this.quantity;
          this.initialPrice = this.price;
        }
      },
    },
    isDirtyQuantity(isDirty) {
      if (!isDirty) {
        this.updatePrice(this.initialPrice);
      }
    },
  },
};
</script>

<style lang="scss">
.quantity-field {
  display: flex;
  align-items: center;
}
</style>
