import { watch } from 'vue';
import moment from 'moment/moment';
import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';
import UserModule from '@/store/user';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import PageEntity from '@/lib/layouts/page/pageEntity';
import filterModel from './reportFilter';
import { prepareReviseList, prepareReportInfo, ReviseModelInterface } from '@/lib/Revise';
import { getReportReviseList, getReport, setReport, downloadReviseReport } from '@/api/revise';
import ReviseReportFilterModule from './reportFilterEntity';
import { PageSort } from '@/lib/layouts/page/page.interface';
import { Filter, FilterEntityModel } from '@/lib/layouts/page/filter.interface';
import { getAllPartnersList } from '@/api/partners';
import { getClientShiftById, getShopMinById } from '@/api/shop';
import { convertObjectToQueryParams, saveBlob } from '@/lib/Utils';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { strings } from '@/lib/stringConst';
import { ShopMinEntity } from '@/interfaces/models/shop.interface';
import { Checkbox } from '@/interfaces/filter.interface';
import { CatchFormResponse } from '@/interfaces/shared';
import { PartnerSelectListItem } from '@/interfaces/partner.interface';
import { getServiceTypesByStore } from '@/api/templates';
import { getLunchSchemes } from '@/api/client';
import { LunchSchemesModel } from '@/interfaces/models/customer.interface';
import { ShiftEntityInterface } from '@/store/shops/shiftModalParams';

export const MODULE_NAME = 'reviseReport';
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class ReviseReportModule extends PageBaseModule {
  title = '';
  hasCabinet = false;
  shopId: string | null = null;
  shiftId: number | null = null;
  filter: Filter;
  model: ReviseModelInterface | {};
  shiftModel: ShiftEntityInterface | {} = {};
  modal: boolean;
  isTest = false;
  timeZone = 3;
  lunchSchemes: LunchSchemesModel[] = [];
  queryStringFilters = {
    monthPeriod: '',
    partners: '',
  };

  get filterParams() {
    const filterModel = this.filter.filterSettings.filterModel;

    const monthPeriod = filterModel.monthPeriod.current?.id ?? '';
    const serviceTypes = filterModel.serviceTypes?.checkedItems?.map(({ id }) => id) ?? [];
    const partner = UserModule.isSupervisor ? filterModel.partners.current?.id : '';
    const fio = filterModel.fio.value ?? '';
    const showDone = filterModel.showDone.checked ? '1' : '0';

    const result: Record<string, unknown> = {
      monthPeriod,
      serviceTypes,
      fio,
      showDone,
    };

    if (partner) {
      result.partners = [partner];
    }

    return result;
  }

  constructor(module: ReviseReportModule) {
    super(module);

    const page = new PageEntity();
    page.setTitle('Сверка');
    page.setTitleEdit('store');
    page.values.actionPagination = 'reviseReport/updatePage';
    this.pageSettings = page.values;

    const filter = new filterModel();
    this.filter = getModule(ReviseReportFilterModule);
    this.filter.setFilterName('reviseReportFilter');
    this.filter.setFilterModel(filter.filterModel);
    this.filter.setFilterHandlers(filter.filterHandlers);

    this.model = {};
    this.modal = false;

    watch(
      () => UserModule.isSupervisor,
      (isSupervisor = false) => {
        if (!isSupervisor) {
          this.filter.resetSelect('partners');
        }
      },
      { immediate: true }
    );
  }

  @Mutation
  SET_SORT(sorts: PageSort) {
    this.pageSettings.sort = sorts;
    window.localStorage.shopsSort = JSON.stringify(sorts);
  }

  @Mutation
  SET_SHOP_ID(id: string) {
    this.shopId = id;
  }

  @Mutation
  SET_SHIFT_ID(id: number) {
    this.shiftId = id;
  }

  @Mutation
  SET_MODAL(flag: boolean) {
    this.modal = flag;
  }

  @Mutation
  SET_MODEL(data: Record<string, string | number | null>) {
    this.model = prepareReportInfo(data);
  }

  @Mutation
  SET_SHIFT_MODEL(data: ShiftEntityInterface) {
    this.shiftModel = data;
  }

  @Mutation
  SET_TITLE(title: string) {
    this.title = title;
  }

  @Mutation
  SET_HAS_CABINET(value: boolean) {
    this.hasCabinet = value;
  }

  @Mutation
  SET_IS_TEST(value: boolean) {
    this.isTest = value;
  }

  @Mutation
  SET_TIMEZONE(value: number) {
    this.timeZone = value;
  }

  @Mutation
  SET_LUNCH_SCHEMES(value: LunchSchemesModel[]) {
    this.lunchSchemes = value;
  }

  @Action({ rawError: true })
  async initFilterValueFromQueryString(type: 'monthPeriod' | 'partners') {
    const getNeededOption = (
      selectFilterModel: Pick<FilterEntityModel, 'current' | 'list'>,
      queryStringFilterValue: string
    ) => {
      return (selectFilterModel.list as FilterEntityModel['current'][])?.find(
        (option) => option?.id === queryStringFilterValue
      );
    };

    const filterSelectModel = this.filter.filterSettings.filterModel[type];
    const queryStringFilterValue = this.queryStringFilters[type];
    const neededOption = getNeededOption(filterSelectModel, queryStringFilterValue);

    filterSelectModel.current = neededOption || { id: '', value: '' };

    if (neededOption) {
      this.filter.updateFilter();
    }
  }

  @Action({ rawError: true })
  async resetShowDone() {
    const showDoneFilterModel = this.filter.filterSettings.filterModel.showDone;

    if (showDoneFilterModel) {
      showDoneFilterModel.checked = true;
    }

    await this.filter.updateFilter();
  }

  @Action({ rawError: true })
  async init(queryStringFilters: { monthPeriod: string; partnerUuid: string }) {
    this.queryStringFilters.monthPeriod = queryStringFilters.monthPeriod;
    this.queryStringFilters.partners = queryStringFilters.partnerUuid;

    this.context.commit('SET_SORT', window.localStorage.reviseSort ? JSON.parse(window.localStorage.reviseSort) : {});
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    await this.getTitle();
    await this.initList();
  }

  @Action({ rawError: true })
  async getTitle() {
    const result: ShopMinEntity = await getShopMinById(this.shopId as string);
    this.context.commit('SET_TITLE', [result.code, result.name].join(', '));
    this.context.commit('SET_HAS_CABINET', result.hasCabinet);
    this.context.commit('SET_IS_TEST', result.isTest);
    this.context.commit('SET_TIMEZONE', result.timeZone);
  }

  @Action({ rawError: true })
  async initList() {
    await this.filter.init();
    await this.initFilterValueFromQueryString('monthPeriod');

    if (UserModule.isSupervisor) {
      await this.initFilterValueFromQueryString('partners');
    }

    await this.resetShowDone();
    await this.getList();
  }

  @Action({ rawError: true })
  async getList() {
    const result = await this.fetchList();

    if (result) {
      await this.context.dispatch('setList', result.calendar);
    }
  }

  @Action({ rawError: true })
  async fetchList() {
    try {
      const queryParams = convertObjectToQueryParams(this.filterParams);
      const sort = await this.getSortForRequest();

      return await getReportReviseList(this.shopId as string, sort, queryParams);
    } catch (error) {
      if (error.response?.data) {
        ResponseHandlerModule.showNotify({
          message: error.response.data,
          type: 'fail',
        });

        return;
      }

      ResponseHandlerModule.showNotify({ message: error, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async setList(calendar: TableApiInterface) {
    const sort = Object.values(this.pageSettings.sort);
    this.context.commit(
      'SET_TABLE',
      prepareReviseList(calendar, { sort, month: (this.filter.filterSettings.filterModel.monthPeriod as any).current.id })
    );
  }

  @Action({ rawError: true })
  setShopId(id: string) {
    this.context.commit('SET_SHOP_ID', id);
  }

  @Action({ rawError: true })
  setShiftId(id: number) {
    this.context.commit('SET_SHIFT_ID', id);
  }

  @Action({ rawError: true })
  async setLunchSchemes() {
    let lunchSchemes = [] as any;

    if (this.shiftId) {
      lunchSchemes = await getLunchSchemes(this.shiftId.toString());
    }

    this.context.commit('SET_LUNCH_SCHEMES', lunchSchemes);
  }

  @Action({ rawError: true })
  clearShopsSort() {
    this.context.commit('SET_SORT', {});
  }

  @Action({ rawError: true })
  async initServiceTypeSelect() {
    const serviceTypes = await getServiceTypesByStore(this.shopId as string);
    const filterModelList = (this.filter.filterSettings.filterModel.serviceTypes?.list ?? []) as Checkbox[];

    return serviceTypes
      .filter((serviceType) => serviceType.count)
      .map((serviceType) => {
        const filterModelItem = filterModelList.find((item) => item.id === serviceType.id);
        const checked = filterModelItem?.checked ?? false;

        return {
          id: serviceType.id,
          value: serviceType.name,
          name: serviceType.name,
          checked,
        };
      });
  }

  @Action({ rawError: true })
  async initMonthsSelect() {
    const items = [];
    const currentMonth = moment().startOf('month');

    for (let i = 0; i < 12; i++) {
      const itemMonth = currentMonth.clone().subtract(i, 'month');

      const id = itemMonth.format('YYYY-MM');
      const value = itemMonth.format('MMMM YYYY').replace(/^[а-я]/, (firstLetter) => firstLetter.toUpperCase());

      items.push({ id, value });
    }

    return items;
  }

  @Action({ rawError: true })
  async initPartnersSelect() {
    try {
      if (!UserModule.isSupervisor) {
        return [];
      }

      const partnersAll: PartnerSelectListItem[] = await getAllPartnersList();

      return Object.values(partnersAll)
        .filter((partner: PartnerSelectListItem) => partner.isSupplier)
        .map((partner: PartnerSelectListItem) => {
          return {
            id: partner.uuid,
            value: partner.legalName,
            name: partner.legalName,
          };
        });
    } catch (e) {
      return [];
    }
  }

  @Action({ rawError: true })
  async getReportInfo(id: string) {
    const report = await getReport(id);
    const shift = await getClientShiftById(report.clientMarketShiftId);

    this.context.commit('SET_SHIFT_MODEL', shift);
    this.context.commit('SET_MODEL', report);
  }

  @Action({ rawError: true })
  async setReport(report: { id: string; data: { status?: string; lunch?: number; end?: string; start?: string } }) {
    try {
      await setReport(report.id, report.data);
      this.context.commit('SET_MODAL', false);
      await this.getList();
    } catch (error) {
      ResponseHandlerModule.handleResponseError({ error, checkErrorFields: true, defaultMessage: strings.UNKNOWN_ERROR });
    }
  }

  @Action({ rawError: true })
  async downloadReviseReport() {
    try {
      const filterParams: Record<string, unknown> = { ...this.filterParams, clientMarket: this.shopId };
      const queryString = convertObjectToQueryParams(filterParams);

      const result = await downloadReviseReport(queryString);
      const filename = `report_${filterParams.monthPeriod}.xlsx`;

      saveBlob(result, filename);
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: (error as CatchFormResponse).response?.data?.message ?? error,
        type: 'fail',
      });
    }
  }
}

export default getModule(ReviseReportModule);
